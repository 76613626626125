<template>
  <div class="information-page">
    <p class="page-title">
      结算单申诉：
    </p>
    <p class="page-title">
      如对该结算单有异议，请联系王伟 ,电话为:18538206788
    </p>
    <van-button block type="info" native-type="button" @click="$router.back()">
      返回
    </van-button>
  </div>
</template>

<script>
import { Button, Icon } from 'vant'
export default {
  name: 'InformationPage',
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon
  },
  props: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style>

</style>
