var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "information-page" },
    [
      _c("p", { staticClass: "page-title" }, [_vm._v(" 结算单申诉： ")]),
      _c("p", { staticClass: "page-title" }, [
        _vm._v(" 如对该结算单有异议，请联系王伟 ,电话为:18538206788 ")
      ]),
      _c(
        "van-button",
        {
          attrs: { block: "", type: "info", "native-type": "button" },
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [_vm._v(" 返回 ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }